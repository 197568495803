<template>
    <div v-if="getReviews" class="dashboard">
      <div class="reviews-options">

          <h1>
            {{ $t('ratings.ratings') }}
            <div v-if="getUserReviewsInfos" class="review-page-header-info">
              <el-tooltip
                  class="tooltip-rating"
                  effect="dark"
                  :content="ranks[getUserReviewsInfos.ranking_code - 1]"
                  placement="top"
              >
                  <img :src="require(`@/assets/svg/v2/starPlain_${getUserReviewsInfos.ranking_code}.svg`)" alt="User rating review" width="20" class="img-grade-review" />
              </el-tooltip>

                <h4 :class="{ rankingBronze: getUserReviewsInfos.user_rating_count > 9, rankingArgent: getUserReviewsInfos.user_rating_count > 49, rankingGold: getUserReviewsInfos.user_rating_count > 99, rankingGreen: getUserReviewsInfos.user_rating_count > 249, rankingRed: getUserReviewsInfos.user_rating_count > 499, rankingTep: getUserReviewsInfos.user_rating_count > 999 }">{{ getUserDetails.identity.pseudo || '-' }}</h4>
                <p>({{ getUserReviewsInfos.user_rating_count || 0 }})</p>
              </div>
            </h1>
        
            <div class="orders-searchbar">
                <input v-model="search" type="text" :placeholder="$t('ratings.search')" />
                <button class="action-light">
                    <img src="@/assets/svg/v2/search.svg" alt="Search icon" class="themed-img"/>
                </button>
            </div>
      </div>

      <div class="reviews">
        <el-table
          :data="visibleReviewsFiltered"
          class="bottles-list orders-list"
          stripe
        >
  
          <el-table-column
            :label="$t('orders.creation_date')"
            prop="date"
            width="180"
          >
            <template v-slot="{ row }">
              <a class="title">{{ dateConvert(row.created_at) }}</a>
            </template>
          </el-table-column>
  
          <el-table-column :label="$t('product_page.spirit_type')" sortable prop="spirit_type_label" min-width="80"></el-table-column>
          <el-table-column label="Ticker" width="140" sortable prop="ticker"></el-table-column>
          <el-table-column :label="$t('search.name')" sortable prop="title" min-width="250"></el-table-column>
          <el-table-column :label="$t('ratings.score')" width="70" sortable prop="score"></el-table-column>
  
          <el-table-column label="" prop="actions" width="130">
            <template v-slot="{ row }">
              <div class="buttons">
                <button @click="updateR(row)" class="download-invoice">
                  <el-tooltip
                    :content="$t('ratings.see_or_update')"
                    class="tooltip"
                    effect="dark"
                    placement="top"
                  >
                    <img alt="Delete alert" src="@/assets/svg/v2/edit.svg" class="themed-img" />
                  </el-tooltip>
                </button>

                <button @click="deleteR(row)" class="download-invoice">
                  <el-tooltip
                    :content="$t('ratings.delete_btn')"
                    class="tooltip"
                    effect="dark"
                    placement="top"
                  >
                    <img alt="Delete alert" src="@/assets/svg/v2/delete.svg" class="themed-img" />
                  </el-tooltip>
                </button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="create-order-help">
        <img src="@/assets/svg/v2/infos.svg" alt="Informations create review" class="themed-img" width="23" />
        <p>{{ $t('ratings.rating_explain') }}</p>
      </div>

      <el-drawer
        v-if="reviewUpdate && getUserDetails"
        v-model="drawerUpdateReview"
        :title="reviewUpdate.title"
        :size="drawerSize"
      >

        <h4>Pseudo</h4>
        <el-input
          v-model="getUserDetails.identity.pseudo"
          maxlength="50"
          minlength="5"
          type="text"
          disabled
          :placeholder="$t('ratings.pseudo_placeholder')"
        />

        <h4 class="review-input-title">{{ $t('ratings.score') }}</h4>
        <el-input
          v-model="reviewUpdate.score"
          :class="{ errorInput: regexUpdateReview.score }"
          max="100"
          min="1"
          type="number"
        />

        <h4 class="review-input-title">{{ $t('ratings.description') }}</h4>
        <el-input
          v-model="reviewUpdate.description"
          :rows="5"
          type="textarea"
          :placeholder="$t('ratings.description')"
        />

        <el-checkbox v-model="reviewUpdate.private_description" :label="$t('ratings.private_description')" />

        <div class="review-update-actions">
          <button
            :disabled="isLoading"
            v-loading="isLoading"
            class="update-review-btn action-light"
            @click.prevent="updateReviewFct()"
          >
            <span>{{ $t('ratings.update_btn') }}</span>
          </button>
        </div>

        <ranking />

      </el-drawer>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex'
  import { filter } from 'lodash'
  import moment from 'moment'
  import ranking from '@/components/modules/reviews/ranking.vue'

  export default {
    components: {
      ranking
    },
    data() {
      return {
        search: '',
        reviewUpdate: null,
        drawerUpdateReview: false,
        isLoading: false,
        ranks: ['Newcomer', 'Enthusiast', 'Expert', 'Connoisseur', 'Curator', 'Master Taster', 'Legend'],

        regexUpdateReview: {
          score: false,
          description: false
        }
      }
    },
    async mounted() {
      await this.reviews();
      await this.getClient();
    },
    computed: {
      ...mapGetters({
        getReviews: 'getReviews',
        getUserDetails: 'getUserDetails',
        getUserReviewsInfos: 'getUserReviewsInfos'
      }),
      drawerSize() {
      if (window.innerWidth < 500) {
        return '100%'
      }
      return '50%'
    },
      visibleReviewsFiltered() {        
        return filter(this.getReviews, (o) => {           
    
            return (o.ticker.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
                o.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
                String(o.created_at).indexOf(this.search) !== -1 ||
                String(o.score).indexOf(this.search) !== -1) ||
                String(o.spirit_type_label.toLowerCase()).indexOf(this.search.toLowerCase()) !== -1
        });
      },
    },
    methods: {
      ...mapActions({
        allOrdersAlertsList: 'allOrdersAlertsList',
        updateOrderAlertAction: 'updateOrderAlertAction',
        reviews: 'reviews',
        updateReview: 'updateReview',
        deleteReview: 'deleteReview',
        getClient: 'getAccountDetails',
      }),
      dateConvert(date) {
        return moment(date).format('DD/MM/YYYY HH:mm')
      },
      updateR(r) {
        this.reviewUpdate = r;
        this.drawerUpdateReview = true;
      },
      async updateReviewFct() {
        await this.updateReview(this.reviewUpdate).then(() => {

          this.drawerUpdateReview = false;

        }).finally(() => {
          this.isLoading = false;
        })
      },
      deleteR(r) {
        this.deleteReview(r.ticker);
      }
    }
  }
</script>
  
<style lang="scss">
  @import '@/assets/styles/root.scss';

  .dashboard {
    margin: 30px;
    margin-top: 20px;

    .reviews-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 30px;
        display: flex;
    }

    h1 {
      display: flex;

      .review-page-header-info {
        display: flex;
        align-items: center;
        margin-left: 10px;

        h4 {
          font-size: 16px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          margin-left: 3px;
        }
      }
    }
  }

  .orders-searchbar {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    
    input {
      box-sizing: border-box;
      width: 240px;
      height: 32px;
      border: 1px solid var(--border);
      background: var(--background-color-2);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 5px 10px;
      font-size: 15px;
    }
    button {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 32px;
      background: $primary-color;
      border: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 5px 12px;
      font-size: 15px;
    }
  }

  .order-alert-update-icon {
    cursor: pointer;
  }
  
  .dashboard {
    min-height: 52%;
    margin-top: 20px;
  
    h1 {
      margin-bottom: 6px;
      padding-left: 0;
    }
  }
  
  .reviews {
    border-radius: 12px;
    border: 1px solid var(--border);
    background: var(--background-color-2);
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0;
    overflow: hidden;
  }
  
  .buttons {
    display: flex;
    justify-content: left;
    gap: 20px;
  }
  
  .title,
  .price {
    display: flex;
    text-align: start;
    word-break: keep-all;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 500;
    border: 0;
    background: transparent;
  }
  
  h1 {
    padding-left: 30px;
  }
  
  .ticker-maitre {
    border-radius: 6px;
    border: 1px solid var(--border-contrast);
    color: var(--text-color);
    background: transparent;
    padding: 3px;
  }
  
  .cell {
    display: flex;
    justify-content: start;
  }
  
  .download-invoice {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--text-color);
  
    img {
      width: 20px;
    }
  }
  
  .el-table tr,
  .el-table th.el-table__cell {
    background-color: var(--background-color-2);
    font-size: 15px;
    font-weight: 500;
  }
  
  .el-table th.el-table__cell.is-leaf,
  .el-table td.el-table__cell {
    border-bottom: 1px solid var(--border);
  }
  
  .el-table thead {
    color: var(--text-color);
  }
  
  .el-table__body tr:hover > td.el-table__cell {
    background-color: transparent;
  }

  .cell {
    color: var(--text-color);
  }
  
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell,
  .el-table--striped:hover
    .el-table__body
    tr.el-table__row--striped:hover
    td.el-table__cell {
    background: var(--background-color-11);
  }
  
  .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: var(--background-color-3);
  }
  
  .el-table__inner-wrapper::before {
    background-color: transparent;
  }

  .create-order-help {
    display: flex;
    align-items: center;

    img {
        margin-right: 8px;
    }
  }

  .review-input-title {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .review-update-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .update-review-btn {
      padding: 5px 8px;
      width: 100%;
      max-width: 185px;
      border-radius: 6px;
      font-weight: 400;
      font-size: 16px;
      border: none;
      color: var(--text-color);
      background: $primary-color;
    }
  }
  
  @media screen and (max-width: 500px) {
  
    .hidden-tel {
      display: none;
    }
  
    .orders-list {
      display: block !important;
    }
  
    .dashboard {
      max-width: 100%;
      box-sizing: border-box;
      margin: 5px;
      margin-top: 20px;

      .reviews-options {
        flex-direction: column;
      }
  
      h1 {
        margin-bottom: 10px !important;
        padding-left: 10px;
      }
    }
  
    .orders-searchbar {
      margin-left: 0;
  
      input {
        width: 80%;
      }
  
      button {
        width: 20%;
        justify-content: center;
      }
    }
  
    .reviews {
      margin: 5px;
    }
  }
</style>
  